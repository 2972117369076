import { type AxiosInstance } from 'axios'
import { useMutation } from '@tanstack/react-query'

import { MutationOptions } from '@utils/queries'
import { useToast } from 'mysaiva-web-components'
import { ApiError } from '../../../services/api'

export type InvitationPayload = {
  payload: {
    token: string
  }
}

export type UseInvitationArgs = {
  apiClient: AxiosInstance
}

export function useInvitation(
  args: UseInvitationArgs,
  options: MutationOptions<InvitationPayload, null> = {}
) {
  const { apiClient } = args

  const toast = useToast()

  function onError(error: ApiError): void {
    toast.error({ message: error.localizedError })
  }

  async function acceptInvitation(payload: InvitationPayload): Promise<null> {
    const res = await apiClient.post<null>(
      `v2/invitations/accept`,
      payload.payload
    )
    return res.data
  }

  return useMutation({
    ...options,
    mutationFn: acceptInvitation,
    onError
  })
}

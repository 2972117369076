import { useToast } from 'mysaiva-web-components'
import { useMutation } from '@tanstack/react-query'
import type { AxiosInstance } from 'axios'
import { MutationOptions } from '@utils/queries'
import { ApiError } from '../../../services/api'
import { OtpLogin, OtpPhrase } from '../../../types/api'

export type UseRequestOTPArgs = {
  apiClient: AxiosInstance
}

export type UseRequestOTPOptions = MutationOptions<
  OtpLogin,
  OtpPhrase,
  null,
  ApiError
>

export function useRequestOTP(
  args: UseRequestOTPArgs,
  options: UseRequestOTPOptions = {}
) {
  const { apiClient } = args

  const toast = useToast()

  function onError(error: ApiError) {
    toast.error({ message: error.localizedError })
  }

  async function requestOTP(args: OtpLogin) {
    const res = await apiClient.post<OtpPhrase>(`v2/auth/otp`, {
      ...args,
      platform: 'web'
    })

    return res.data
  }

  return useMutation({
    onError,
    mutationFn: requestOTP,
    ...options
  })
}

import { useMutation } from '@tanstack/react-query'
import { MutationOptions } from '@utils/queries'
import { useToast } from 'mysaiva-web-components'
import { type ApiError } from '../../../services/api'
import { apiClient } from '../../../services/api'
import { OtpLoginWithPassword, LoginTokens } from '../../../types/api'

export function useLogin(
  options: MutationOptions<OtpLoginWithPassword, LoginTokens> = {}
) {
  const toast = useToast()

  function onError(
    error: ApiError,
    vars: OtpLoginWithPassword,
    ctx: unknown
  ): void {
    options.onError?.(error, vars, ctx)
    toast.error({ message: error.localizedError })
  }

  async function login(args: OtpLoginWithPassword): Promise<LoginTokens> {
    const res = await apiClient.post<LoginTokens>(`v2/auth/otp/token`, args)
    return res.data
  }

  return useMutation({
    ...options,
    mutationFn: login,
    onError
  })
}

import { useToast } from 'mysaiva-web-components'
import { type AxiosInstance } from 'axios'
import { type QueryClient, useMutation } from '@tanstack/react-query'
import { AuthenticatedUser } from '../types'
import { authenticatedUserQueryKey } from '../queries'
import { ApiError } from '../../../services/api'

type UpdateAuthenticatedUserArgs = Partial<AuthenticatedUser>

export type UseUpdateAuthenticatedUserArgs = {
  queryClient: QueryClient
  apiClient: AxiosInstance
}

export function useUpdateAuthenticatedUser(
  args: UseUpdateAuthenticatedUserArgs
) {
  const { queryClient, apiClient } = args

  const toast = useToast()

  function onError(error: ApiError) {
    toast.error({ message: error.localizedError })
  }

  async function updateAuthenticatedUser(
    args: UpdateAuthenticatedUserArgs
  ): Promise<AuthenticatedUser> {
    const res = await apiClient.patch<AuthenticatedUser>('v2/users/me', {
      payload: args
    })
    return res.data
  }

  async function onSuccess(): Promise<void> {
    await queryClient.invalidateQueries({ queryKey: authenticatedUserQueryKey })
  }

  return useMutation({
    onError,
    mutationFn: updateAuthenticatedUser,
    onSuccess
  })
}

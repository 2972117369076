import { type AxiosInstance } from 'axios'
import { useMutation } from '@tanstack/react-query'
import { type IUserStorage } from '../../../services/utils/local-storage'

export type UseLogoutArgs = {
  location: Location
  apiClient: AxiosInstance
  savedUser: IUserStorage
}

export function useLogout({ savedUser, apiClient, location }: UseLogoutArgs) {
  async function logout() {
    const refreshToken = savedUser.getRefresh()

    if (refreshToken) {
      await apiClient.post('v2/auth/jwt/logout', {
        refresh_token: refreshToken
      })
    } else {
      return Promise.resolve()
    }
  }

  function onLogoutSettled() {
    location.replace(`/login?redirect=${location.pathname + location.search}`)
    savedUser.clear()
  }

  return useMutation({
    mutationFn: logout,
    onSettled: onLogoutSettled
  })
}
